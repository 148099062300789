import React from 'react';
import { MessageSquare } from 'react-feather';

const leadsPages = [
    {
        id: 'leads-menu',
        title: 'Leads',
        navLink: '',
        group: ['admin'],
        type: 'collapse',
        icon: <MessageSquare size={20} />,
        children: [
            {
                id: 'petshop-leads',
                title: 'Novos estabelecimentos',
                navLink: '/petshop-leads',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'new-petshop-leads',
                title: 'Estabelecimentos sugeridos',
                navLink: '/new-petshop-leads',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'franchesee-leads',
                title: 'Novos Fraqueados',
                navLink: '/franchisee-leads',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            // {
            //     id: 'users-leads',
            //     title: 'Usuários',
            //     navLink: '/users-leads',
            //     type: 'item',
            //     group: ['admin'],
            //     icon: null,
            // },
        ],
    },
];

export default leadsPages;
