import React from 'react';
import { Sliders } from 'react-feather';

const configPages = [
    {
        id: 'config-menu',
        title: 'Configuração',
        navLink: '',
        group: ['admin'],
        type: 'collapse',
        icon: <Sliders size={20} />,
        children: [
            {
                id: 'banners-list',
                title: 'Todos',
                navLink: '/configs',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'banners',
                title: 'Banner',
                navLink: '/banners',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'vouchers',
                title: 'Valor do voucher',
                navLink: '/vouchers',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
        ],
    },
];

export default configPages;
