/**
 *
 * Actions Types
 *
 */
export const Types = {
    AUTH_SUCCESS: 'auth/AUTH_SUCCESS',
    AUTH_SET_TOKEN: 'auth/AUTH_SET_TOKEN',
    AUTH_LOGOUT: 'auth/AUTH_LOGOUT',
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    globalLoading: true,

    token: null,
    isAuth: null,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case Types.AUTH_SET_TOKEN:
            return {
                ...state,
                token: payload.token,
                isAuth: payload.isAuth,
            };

        case Types.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
                message: payload.message,
            };
        case Types.AUTH_LOGOUT:
            return {
                ...state,
                loading: false,
                token: null,
                isAuth: false,
            };

        default:
            return state;
    }
}

/**
 *
 * Actions Creators
 *
 */
export const authLogin = (payload) => ({
    type: Types.AUTH_SET_TOKEN,
    payload: payload,
});
export const authLoginSuccess = (payload) => ({
    type: Types.AUTH_SUCCESS,
    payload,
});
export const authLogout = () => ({ type: Types.AUTH_LOGOUT });
