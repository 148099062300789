import React from 'react';
import { NavLink } from 'react-router-dom';

import Logo from 'assets/img/logo/logo.svg';

const NavbarHeader = () => {
    return (
        <div className="navbar-header">
            <NavLink to="/" className="navbar-brand">
                <div className="brand-logo">
                    <img src={Logo} />
                </div>
            </NavLink>
        </div>
    );
};

export default NavbarHeader;
