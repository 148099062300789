import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/financial',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/financial'),
            loading: LoadingComponent,
        }),
    },
];
