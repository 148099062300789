import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/users',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/users'),
            loading: LoadingComponent,
        }),
    },
];
