import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/petshop-leads',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/leads/petshopLeads'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/new-petshop-leads',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/leads/recomendationLeads'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/franchisee-leads',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/leads/franchiseeLeads'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/users-leads',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/leads/usersLeads'),
            loading: LoadingComponent,
        }),
    },
];
