import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const MenuItem = ({ menuItem }) => {
  const { title, navLink, icon } = menuItem;
  const [itemHover, setItemHover] = useState(false);
  const location = useLocation();

  let currentBase = location.pathname;

  const active = currentBase === navLink ? 'active' : '';
  
  return(
    <li 
      className={`nav-item ${active} ${itemHover ? 'hover' : ''}`}
      onMouseEnter={() => setItemHover(true)}
      onMouseLeave={() => setItemHover(false)}
    >
      <Link to={navLink}>
        <div className="menu-text">
          {icon}
          <span className="menu-item menu-title">
            {title}
          </span>
        </div>
      </Link>
    </li>
  );
}

export default MenuItem;
