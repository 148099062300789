/**
 *
 * Actions Types
 *
 */
export const Types = {
    CATEGORY_SUCCESS: 'category/CATEGORY_SUCCESS',
    CATEGORY_ERROR: 'category/CATEGORY_ERROR',

    CATEGORY_RETRIVE_ALL: 'category/CATEGORY_RETRIVE_ALL',
    CATEGORY_SET: 'category/CATEGORY_SET'
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    rows: [],
    rowsOption: [],

    loading: false,
    success: false,
    error: null
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action

    switch (type) {
        case Types.CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.CATEGORY_RETRIVE_ALL:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.CITY_RETRIVE_BY_ISO:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.CATEGORY_SET:
            return {
                ...state,
                rows: payload.rows,
                rowsOption: payload.rowsOption,
            }

        default:
            return state
    }
}

/**
 *
 * Actions Creators
 *
 */
export const retriveAllCategories = payload => ({ type: Types.CATEGORY_RETRIVE_ALL, payload: payload });
