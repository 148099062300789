import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/faq',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/faq'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/faq/create',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/faq/form'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/faq/:id',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/faq/form'),
            loading: LoadingComponent,
        }),
    },
];
