import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/petshops',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/petshops'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/create',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/petshops/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/edit',
        exact: true,
        auth: true,
        group: ['store'],
        component: Loadable({
            loader: () => import('pages/petshops/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/edit/:id',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/petshops/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/voucher/:id',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/voucher/:id/exceptions',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/exceptions/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/voucher/:id/exceptions/create',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/exceptions/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/voucher/:id/exceptions/:exception',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/exceptions/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/voucher/:id/schedule',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/schedule/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/voucher/:id/schedule/day',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/schedule/day/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/reviews/',
        exact: true,
        auth: true,
        group: ['store'],
        component: Loadable({
            loader: () => import('pages/petshops/reviews'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/petshops/reviews/:id',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/petshops/reviews'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/voucher',
        exact: true,
        auth: true,
        group: ['store'],
        component: Loadable({
            loader: () => import('pages/petshops/voucher/index'),
            loading: LoadingComponent,
        }),
    },
];
