/**
 *
 * Actions Types
 *
 */

export const Types = {
    SET_DATA: 'user/SET_DATA',
    CLEAN_DATA: 'user/CLEAN_DATA',
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    id: '',
    name: '',
    group: '',
    profile: {
        id: '',
        first_name: '',
        last_name: '',
    },
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case Types.SET_DATA:
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                group: payload.group,
                profile: payload.profile,
            };

        case Types.CLEAN_DATA:
            return {
                ...state,
                id: null,
                name: null,
                group: null,
                profile: null,
            };

        default:
            return state;
    }
}

/**
 *
 * Actions Creators
 *
 */
export const setDataUser = (payload) => ({ type: Types.SET_DATA, payload });
export const clearDataUser = (payload) => ({ type: Types.CLEAN_DATA, payload });
