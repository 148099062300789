import React from 'react';

const MenuTitle = (title = '') => {
  return (
    <li className="navigation-header">
      <span>{title}</span>
    </li>
  );
}

export default MenuTitle;
