import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/videos',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/videos/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/videos/preview',
        exact: true,
        auth: true,
        group: ['admin', 'store', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/videos/preview'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/videos/create',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/videos/form'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/videos/edit/:id',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/videos/form'),
            loading: LoadingComponent,
        }),
    },


    // Categories
    {
        path: '/videos/categories',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/videos/categories/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/videos/categories/create',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/videos/categories/form'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/videos/categories/edit/:id',
        exact: true,
        auth: true,
        group: ['admin'],
        component: Loadable({
            loader: () => import('pages/videos/categories/form'),
            loading: LoadingComponent,
        }),
    },
];
