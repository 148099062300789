import React, { useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { fetchUser } from 'services/user';
import { saveToken, removeToken } from 'services/auth';

import { authLogin } from 'ducks/auth';
import { setDataUser } from 'ducks/user';

import Routes from './routes';

import 'components/@vuexy/rippleButton/RippleButton';
import 'prismjs/themes/prism-tomorrow.css';

import ComponentSpinner from 'components/@vuexy/spinner/Fallback-spinner';

const App = () => {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        const token = window.localStorage.getItem('token');

        if (token) {
            saveToken(token);

            fetchUser()
                .then(async (res) => {
                    await dispatch(authLogin({ token, isAuth: true }));
                    await dispatch(
                        setDataUser({
                            id: res.id,
                            name: res.username,
                            group: res.group,
                            profile: res.profile || {},
                        })
                    );
                })
                .catch(() => {
                    removeToken();
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [dispatch]);

    if (loading) {
        return <ComponentSpinner />;
    }

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
};

export default App;
