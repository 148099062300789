/**
 *
 * Actions Types
 *
 */
export const Types = {
    ENTERPRISE_LOADING: 'enterprise/ENTERPRISE_LOADING',
    ENTERPRISE_SUCCESS: 'enterprise/ENTERPRISE_SUCCESS',
    ENTERPRISE_ERROR: 'enterprise/ENTERPRISE_ERROR',
    ENTERPRISE_CLEAN: 'enterprise/ENTERPRISE_CLEAN',

    ENTERPRISE_GET_PAGE: 'enterprise/ENTERPRISE_GET_PAGE',
    ENTERPRISE_GET: 'enterprise/ENTERPRISE_GET',
    ENTERPRISE_CREATE: 'enterprise/ENTERPRISE_CREATE',
    ENTERPRISE_UPDATE: 'enterprise/ENTERPRISE_UPDATE',
    ENTERPRISE_UPDATE_VOUCHER: 'enterprise/ENTERPRISE_UPDATE_VOUCHER',
    ENTERPRISE_DELETE: 'enterprise/ENTERPRISE_DELETE',
    ENTERPRISE_REQUEST_GEOLOCATION: 'enterprise/ENTERPRISE_REQUEST_GEOLOCATION',
    ENTERPRISE_SET_LOCATION: 'enterprise/ENTERPRISE_SET_LOCATION',

    ENTERPRISE_SET_PAGINATE: 'enterprise/ENTERPRISE_SET_PAGINATE',
    ENTERPRISE_SET: 'enterprise/ENTERPRISE_SET',
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    loading: false,
    success: false,
    error: null,

    data: {
        franchisee_id: "",
        email: "",
        username: "",
        password: "",
        company_name: "",
        phone: "",
        address: {
            name: "",
            street: "",
            state: "",
            city: "",
            number: "",
            street_line_two: "",
            district: "",
            zip: "",
            latitude: "",
            longitude: "",
        },
        show: false,
        website: "",
        voucher: {},
        categories: [],
        created: ""
    },

    enterpriseLocation: {
        lat: 0,
        lng: 0
    },

    rows: [],
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action

    switch (type) {
        case Types.ENTERPRISE_CLEAN:
            
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
                data: {},
                rows: [],
                page: 1,
                pages: 1,
                total: 0,
                limit: 10,
            }

        case Types.ENTERPRISE_GET_PAGE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.ENTERPRISE_CREATE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.ENTERPRISE_GET:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.ENTERPRISE_UPDATE  :
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.ENTERPRISE_DELETE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.ENTERPRISE_UPDATE_VOUCHER:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.ENTERPRISE_SET_PAGINATE:
            return {
                ...state,
                rows: payload.rows,
                page: payload.page,
                pages: payload.pages,
                total: payload.total,
                limit: payload.limit,
            }

        case Types.ENTERPRISE_SET_LOCATION:
            return{
                ...state,
                enterpriseLocation: payload
            }

        case Types.ENTERPRISE_SET:
            
            return {
                ...state,
                data: {
                    company_name: payload.company_name,
                    email: payload.email,
                    franchisee_id: payload.franchisee_id,
                    
                    website: payload.website,
                    phone: payload.phone,
                    address: payload.address,
                    categories: payload.categories,
                    conveniences: payload.conveniences,
                    show: payload.show,

                    voucher: payload.voucher,
                    
                    username: payload.username,
                    password: payload.password,

                    created: payload.created
                }
            }


        case Types.ENTERPRISE_LOADING:
            return {
                ...state,
                loading: payload,
            }

        case Types.ENTERPRISE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.ENTERPRISE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: payload.error
            }

        default:
            return state
    }
}

/**
 *
 * Actions Creators
 *
 */
export const enterpriseClean    = () => ({ type: Types.ENTERPRISE_CLEAN });
export const enterpriseGetPage  = payload => ({ type: Types.ENTERPRISE_GET_PAGE, payload: payload });

export const enterpriseGet             = payload => ({ type: Types.ENTERPRISE_GET, payload: payload });
export const enterpriseCreate          = payload => ({ type: Types.ENTERPRISE_CREATE, payload: payload });
export const enterpriseUpdate          = payload => ({ type: Types.ENTERPRISE_UPDATE, payload: payload });
export const enterpriseUpdateVoucher   = payload => ({ type: Types.ENTERPRISE_UPDATE_VOUCHER, payload: payload });
export const enterpriseDelete          = payload => ({ type: Types.ENTERPRISE_DELETE, payload: payload });
export const requestGeoLocation     = payload => ({ type: Types.ENTERPRISE_REQUEST_GEOLOCATION, payload: payload });
