import React from 'react';
import { CreditCard } from 'react-feather';

export default {
    id: 'financial',
    title: 'Financeiro',
    navLink: '/financial',
    group: ['admin', 'franchisee'],
    icon: <CreditCard size={20} />,
};
