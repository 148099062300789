import React from 'react';
import { HelpCircle } from 'react-feather';

const faqPages = [
    {
        id: 'faq-menu',
        title: 'FAQ',
        type: 'collapse',
        navLink: '',
        group: ['admin'],
        icon: <HelpCircle size={20} />,
        children: [
            {
                id: 'faq-list',
                title: 'Todos',
                navLink: '/faq',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'faq-new',
                title: 'Cadastrar',
                navLink: '/faq/create',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
        ],
    },
];

export default faqPages;
