import Http from 'lib/http';

/**
 *
 * @param {string} code
 */
export const requestOrder = (code) =>
    new Promise((resolve, reject) => {
        return Http.get(`/order/getByCode/${code}`)
            .then((response) => {
                if (response && response.data.success) {
                    const { data } = response.data;
                    resolve(data);
                }

                reject(response);
            })
            .catch((err) => reject(err));
    });

/**
 *
 * @param {string} code
 */
export const requestConfirmVoucher = (code) =>
    new Promise((resolve, reject) => {
        return Http.put(`/order/${code}`)
            .then((response) => {
                if (response && response.data.success) {
                    const { data } = response.data;
                    resolve(data);
                }

                reject(response);
            })
            .catch((err) => reject(err));
    });

/**
 *
 * @param {string} data
 */
export const requestOrders = (data) =>
    new Promise((resolve, reject) => {
        return Http.get('/order', { params: data })
            .then((response) => {
                if (response && response.data.success) {
                    const { data } = response.data;
                    resolve(data);
                }

                reject(response);
            })
            .catch((err) => reject(err));
    });
/**
 *
 * @param {string} data
 */
export const requestAllOrdersToCsv = (data) => {
    return new Promise((resolve, reject) => {
        return Http.get('/order/csv-order', { params: data })
            .then((response) => {
                if (response && response.data.success) {
                    const { data } = response.data;
                    resolve(data);
                }

                reject(response);
            })
            .catch((err) => reject(err));
    });
};

/**
 *
 * @param {string} data
 */
export const requestAllOrdersToAverage = (data) => {
    return new Promise((resolve, reject) => {
        return Http.get('/order/average-order', { params: data })
            .then((response) => {
                if (response && response.data.success) {
                    const { data } = response.data;
                    resolve(data);
                }

                reject(response);
            })
            .catch((err) => reject(err));
    });
};
