import axios from 'axios'

import config from 'config'

const API_URL = `${config.apiGateway.URL}`

axios.defaults.baseURL = API_URL
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error.response)
)

export const setHeaderToken = (token) => {

  axios.defaults.headers.common.Authorization = `Bearer ${token}`

}

export default axios;
