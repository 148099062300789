import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/login',
        exact: true,
        component: Loadable({
            loader: () => import('pages/auth/login'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/forgot-password',
        exact: true,
        component: Loadable({
            loader: () => import('pages/auth/ForgotPassword'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/reset-password/:token',
        exact: true,
        component: Loadable({
            loader: () => import('pages/auth/resetPassword'),
            loading: LoadingComponent,
        }),
    },
    // {
    //     path: "/401",
    //     exact: true,
    //     auth: false,
    //     component: Loadable({
    //         loader: () => import("pages/errors/401"),
    //         loading: LoadingComponent
    //     })
    // }
];
