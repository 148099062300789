import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
  {
    path: '/push-notifications',
    exact: true,
    auth: true,
    group: ['admin'],
    component: Loadable({
      loader: () => import('pages/notifications'),
      loading: LoadingComponent,
    }),
  },
  {
    path: '/push-notifications/message/:id',
    exact: true,
    auth: true,
    group: ['admin'],
    component: Loadable({
      loader: () => import('pages/notifications/form'),
      loading: LoadingComponent,
    }),
  },
];
