import React from 'react';
import { Users } from 'react-feather';

const franchiseesPages = [
    {
        id: 'franchisees-menu',
        title: 'Franqueados',
        type: 'collapse',
        navLink: '',
        group: ['admin'],
        icon: <Users size={20} />,
        children: [
            {
                id: 'franchisees-list',
                title: 'Todos',
                navLink: '/franchisees',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'franchisees-new',
                title: 'Cadastrar',
                navLink: '/franchisee/create',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
        ],
    },
];

export default franchiseesPages;
