/**
 *
 * Actions Types
 *
 */
export const Types = {
    REGIONS_PAGE: "regions/REGIONS_PAGE",
    REGIONS_LOADING: "regions/REGIONS_LOADING",
    REGIONS_SUCCESS: "regions/REGIONS_SUCCESS",
    REGIONS_ERROR: "regions/REGIONS_ERROR",

    CLEAN_FIELDS: "regions/CLEAN_FIELDS",

    REGIONS_SET_OPTION_LIST: "regions/REGIONS_SET_OPTION_LIST",
    REGIONS_SET_LIST: "regions/REGIONS_SET_LIST",
    REGIONS_SET_REGION: "regions/REGIONS_SET_REGION",
    REGION_GET: "regions/REGION_GET",
    REGION_GET_ALL: "regions/REGION_GET_ALL",
    REGIONS_UPDATE: "regions/REGIONS_UPDATE",
    REGIONS_CREATE: "regions/REGIONS_CREATE",
    REGIONS_DELETE: "regions/REGIONS_DELETE",
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    loading: false,
    success: false,
    error: null,

    rows: [],
    rowsOption: [],
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,

    data: {
        name: "",
        cities: [],
        active: false,
        latitude: "",
        longitude: "",
    }
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

        case Types.REGIONS_CREATE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            };
            
        case Types.REGIONS_DELETE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            };
            
        case Types.REGIONS_UPDATE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            };
            
        case Types.REGION_GET:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            };
            
        case Types.REGION_GET_ALL:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            };

        case Types.REGIONS_PAGE:
            return {
                ...state,
                loading: true,
                page: payload.page
            };

        case Types.REGIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            };

        case Types.REGIONS_LOADING:
            return {
                ...state,
                loading: false,
                success: false,
                error: null
            };

        case Types.REGIONS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: payload
            };

        case Types.REGIONS_SET_LIST:
            return {
                ...state,
                rows: payload.rows,
                total: payload.total,
                limit: payload.limit,
                pages: payload.pages
            };

        case Types.REGIONS_SET_OPTION_LIST:
            return {
                ...state,
                rowsOption: payload.rows,
            };

        case Types.REGIONS_SET_REGION:

            return {
                ...state,
                data: {
                    name: payload.name,
                    cities: payload.cities,
                    active: payload.active,
                    latitude: payload.latitude,
                    longitude: payload.longitude,
                }
            };

        case Types.CLEAN_FIELDS:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
            };

        default:
            return state;
    }
}

/**
 *
 * Actions Creators
 *
 */
export const requestPage = payload => ({ type: Types.REGIONS_PAGE, payload });

export const requestAllRegion = () => ({ type: Types.REGION_GET_ALL });
export const requestRegion = payload => ({ type: Types.REGION_GET, payload });
export const createRegion = payload => ({ type: Types.REGIONS_CREATE, payload });
export const updateRegion = payload => ({ type: Types.REGIONS_UPDATE, payload });
export const deleteRegion = payload => ({ type: Types.REGIONS_DELETE, payload });

export const cleanFields = payload => ({ type: Types.CLEAN_FIELDS });
