import Http from 'lib/http';

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */
export const fetchUser = async () => {
    try {
        const response = await Http.get('/identity');

        if (response.data.success) {
            return response.data.data;
        }

        throw response;
    } catch (error) {
        throw error;
    }
};

export const getAllAppUsers = (data) => {
    return new Promise((resolve, reject) => {
        Http.get(`/user`, { params: { ...data } })
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getUsersCsv = () => {
    return new Promise((resolve, reject) => {
        Http.get(`/user/total-users`)
            .then((res) => {
                if (res.data.success) {
                    resolve(res.data.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getCustomers = (page = 1, search = '') => {
    return new Promise((resolve, reject) => {
        Http.get(`/customers`, {
            params: {
                page,
                search
            }
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
};

export const getCustomerById = (id) => {
    return new Promise((resolve, reject) => {
        Http.get(`/customers/${id}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
};
