import React from 'react';
import { Users } from 'react-feather';

export default {
    id: 'users-menu',
    title: 'Usuários',
    navLink: '/users',
    group: ['admin'],
    icon: <Users size={20} />,
};
