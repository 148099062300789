import React, {useState, useEffect} from 'react';

import Menu from './Menu';
import NavbarHeader from './NavbarHeader';

import {
    isMobile
  } from "react-device-detect";

const Navbar = () => {
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        if(!isMobile) {
            setOpen(true)
        }
    },[])
    return (
        <div className={`menu-is-open `}>
            {
                !open && isMobile ?
                <div className="button-close-menu" onClick={() => setOpen(true)}>
                    <span className="bar-menu"/>
                    <span className="bar-menu"/>
                    <span className="bar-menu"/>
                </div>
                :
                <></>
            }
            <div className={`main-menu menu-fixed menu-light menu-accordion menu-shadow theme-primary collapsed ${!isMobile ? 'isBrowser' : ''} ${open && isMobile ? 'isOpen' : ''}`}>
                {
                    isMobile ?
                    <div className="button-open-menu" onClick={() => setOpen(false)}/>
                    :
                    <></>

                }
                <NavbarHeader />
                <div className="scrollbar-container main-menu-content ps ps--active-y">
                    <Menu />
                </div>
            </div>
        </div>
    );
};

export default Navbar;
