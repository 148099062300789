// import React from 'react';
// import { Layers } from 'react-feather';

const petshopsPages = [
    // {
    //   id: 'my-store-menu',
    //   title: 'My pet shop',
    //   navLink: '/my-store',
    //   group: [ 'store' ],
    //   type: 'item',
    // 	icon: <Layers size={20} />,
    // },
];

export default petshopsPages;
