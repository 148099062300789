import { combineReducers } from 'redux';

import auth from 'ducks/auth';
import user from 'ducks/user';
import region from 'ducks/region';
import country from 'ducks/country';
import franchisee from 'ducks/franchisee';
import category from 'ducks/category';
import convenience from 'ducks/convenience';
import petshop from 'ducks/petshop';
import topic from 'ducks/topic';
import enterprise from 'ducks/enterprise';

const appReducer = combineReducers({
    auth,
    user,
    region,
    country,
    enterprise,
    franchisee,
    category,
    convenience,
    petshop,
    topic,
});
const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
