/**
 *
 * Actions Types
 *
 */
export const Types = {
    CONVENIENCE_SUCCESS: 'convenience/CONVENIENCE_SUCCESS',
    CONVENIENCE_ERROR: 'convenience/CONVENIENCE_ERROR',

    CONVENIENCE_RETRIVE_ALL: 'convenience/CONVENIENCE_RETRIVE_ALL',
    CONVENIENCE_SET: 'convenience/CONVENIENCE_SET'
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    rows: [],
    rowsOption: [],

    loading: false,
    success: false,
    error: null
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action

    switch (type) {
        case Types.CONVENIENCE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.CONVENIENCE_ERROR:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.CONVENIENCE_RETRIVE_ALL:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.CONVENIENCE_SET:
            return {
                ...state,
                rows: payload.rows,
                rowsOption: payload.rowsOption,
            }

        default:
            return state
    }
}

/**
 *
 * Actions Creators
 *
 */
export const retriveAllConveniences = payload => ({ type: Types.CONVENIENCE_RETRIVE_ALL, payload: payload });
