import React from 'react';
import { Compass } from 'react-feather';

const regionsPages = [
    {
        id: 'regions-menu',
        title: 'Regiões',
        navLink: '',
        type: 'collapse',
        group: ['admin'],
        icon: <Compass size={20} />,
        children: [
            {
                id: 'regions-list',
                title: 'Todas',
                navLink: '/regions',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'regions-new',
                title: 'Cadastrar',
                navLink: '/regions/create',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
        ],
    },
];

export default regionsPages;
