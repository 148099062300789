import React from 'react';
import { Home} from 'react-feather';

const enterprisePages = [
    {
        id: 'enterprise-menu',
        title: 'Empresas',
        navLink: '',
        group: ['admin', 'franchisee'],
        type: 'collapse',
        icon: <Home size={20} />,
        children: [
            {
                id: 'enterprise-list',
                title: 'Todos',
                navLink: '/enterprise',
                type: 'item',
                group: ['admin', 'franchisee'],
                icon: null,
            },
            {
                id: 'enterprise-new',
                title: 'Cadastrar',
                navLink: '/enterprise/create',
                type: 'item',
                group: ['admin', 'franchisee'],
                icon: null,
            },
        ],
    },
];

export default enterprisePages;
