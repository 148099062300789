import React from 'react';
import { DollarSign } from 'react-feather';

export default {
    id: 'orders-menu',
    title: 'Vouchers vendidos',
    navLink: '/orders',
    group: ['admin', 'franchisee', 'store'],
    icon: <DollarSign size={20} />,
};
