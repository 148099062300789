/**
 *
 * Actions Types
 *
 */
export const Types = {
    COUNTRY_SUCCESS: 'country/COUNTRY_SUCCESS',
    COUNTRY_ERROR: 'country/COUNTRY_ERROR',

    COUNTRY_RETRIVE_ALL: 'country/COUNTRY_RETRIVE_ALL',
    CITY_RETRIVE_BY_ISO: 'country/CITY_RETRIVE_BY_ISO',

    SET_CITY_RETRIVE_BY_ISO: 'country/SET_CITY_RETRIVE_BY_ISO'
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    countries: [],
    cities: [],
    allCities: [],
    citiesFiltered: [],
    isoSelected: 'BR',
    countrySelected: '5b6dda8789449338a3635828',

    loading: false,
    success: false,
    error: null
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action

    switch (type) {
        case Types.COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.COUNTRY_ERROR:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.COUNTRY_RETRIVE_ALL:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.CITY_RETRIVE_BY_ISO:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.SET_CITY_RETRIVE_BY_ISO:
            return {
                ...state,
                cities: payload.cities,
                allCities: payload.allCities,
                isoSelected: payload.isoSelected
            }

        default:
            return state
    }
}

/**
 *
 * Actions Creators
 *
 */
export const retriveAllCountries = payload => ({ type: Types.COUNTRY_RETRIVE_ALL, payload: payload });
export const retriveCitiesByIso = payload => ({ type: Types.CITY_RETRIVE_BY_ISO, payload: payload });
