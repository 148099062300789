import React from 'react';
import { BookOpen } from 'react-feather';

export default {
    id: 'logs-menu',
    title: 'Logs',
    navLink: '/logs',
    group: ['admin'],
    icon: <BookOpen size={20} />,
};
