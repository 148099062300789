import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
  {
    path: '/breeds',
    exact: true,
    auth: true,
    group: ['admin'],
    component: Loadable({
      loader: () => import('pages/breeds/index'),
      loading: LoadingComponent,
    }),
  },
  {
    path: '/breeds/create',
    exact: true,
    auth: true,
    group: ['admin'],
    component: Loadable({
      loader: () => import('pages/breeds/form'),
      loading: LoadingComponent,
    }),
  },
  {
    path: '/breeds/edit/:id',
    exact: true,
    auth: true,
    group: ['admin'],
    component: Loadable({
      loader: () => import('pages/breeds/form'),
      loading: LoadingComponent,
    }),
  },
];
