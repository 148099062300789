import React from 'react';
import { Layers } from 'react-feather';

const petshopsPages = [
    {
        id: 'petshops-menu',
        title: 'Estabelecimentos',
        navLink: '',
        group: ['admin', 'franchisee'],
        type: 'collapse',
        icon: <Layers size={20} />,
        children: [
            {
                id: 'petshops-list',
                title: 'Todos',
                navLink: '/petshops',
                type: 'item',
                group: ['admin', 'franchisee'],
                icon: null,
            },
            {
                id: 'petshops-new',
                title: 'Cadastrar',
                navLink: '/petshops/create',
                type: 'item',
                group: ['admin', 'franchisee'],
                icon: null,
            },
        ],
    },
];

export default petshopsPages;
