import React from 'react';
import {  Globe } from 'react-feather';

const citiesPages = [
    {
        id: 'cities-menu',
        title: 'Cidades',
        navLink: '',
        type: 'collapse',
        group: ['admin'],
        icon: <Globe size={20} />,
        children: [
            {
                id: 'cities-list',
                title: 'Todas',
                navLink: '/cities',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'cities-new',
                title: 'Cadastrar',
                navLink: '/cities/create',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
        ],
    },
];

export default citiesPages;
