const config = {
  dev: {
    app: {
      URL: "https://localhost:3000/",
      BASEDOMAIN: "localhost",
      BASENAME: "https://localhost:3000/",
      CDN: "https://localhost:3000/",
    },
    apiGateway: {
      URL: "https://api.petintime.com.br/api",
      VERSION: "v1",
    },
  },
  hom: {
    app: {
      URL: "https://dev-painel.petintime.com.br/",
      BASEDOMAIN: "petintime.com.br",
      BASENAME: "https://dev-painel.petintime.com.br/",
      CDN: "https://dev-painel.petintime.com.br/",
    },
    apiGateway: {
      URL: "https://dev-api.petintime.com.br/api",
      VERSION: "v1",
    },
  },
  prod: {
    app: {
      URL: "https://painel.petintime.com.br/",
      BASEDOMAIN: "petintime.com.br",
      BASENAME: "https://painel.petintime.com.br/",
      CDN: "https://painel.petintime.com.br/",
    },
    apiGateway: {
      URL: "https://api.petintime.com.br/api",
      VERSION: "v1",
    },
  },
};

export default config[process.env.REACT_APP_MODE || "dev"];
