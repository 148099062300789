import React from 'react';
import PropTypes from 'prop-types';

import Navbar from 'common/navbar';
import Navheader from 'common/navheader';

const propTypes = {
  children: PropTypes.node.isRequired
};

function PrivateLayout({ children }) {
	return (
		<div className="wrapper vertical-layout theme-primary navbar-floating">
			<Navbar />
			<div className={`app-content content`}>
				<Navheader />
				<div className="content-wrapper">
					{children}
				</div>
			</div>
		</div>
	);
}

PrivateLayout.propTypes = propTypes;

export default PrivateLayout;
