import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import { useLocation } from 'react-router-dom';

const MenuCollapse = ({ menuItem }) => {
  const { id, title, navLink, icon, children } = menuItem;

  const [isOpen, setIsOpen] = useState(false);
  const [itemHover, setItemHover] = useState('');
  const location = useLocation();

  const currentBase = location.pathname;
  
  useEffect(() => {
    const { pathname } = location;
    const isOpen = children.some(item => item.navLink === pathname);
    setIsOpen(isOpen);
  }, [location, children]);

  const renderChild = (childItem, key) => {

    const active = currentBase === childItem.navLink ? 'active' : '';

    return(
      <li 
        key={`submenu-${key}`} 
        className={`${active} ${itemHover === childItem.id ? 'hover' : ''}`}
        onMouseEnter={() => setItemHover(childItem.id)}
        onMouseLeave={() => setItemHover('')}
      >
        <Link to={childItem.navLink}>
          <div className="menu-text">
            {childItem.icon}
            <span className="menu-item menu-title">
              {childItem.title}
            </span>
          </div>
        </Link>
      </li>
    );
  }

  const toggleCollapse = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  }

  return(
    <li 
      className={`nav-item has-sub ${isOpen ? 'open' : ''} ${itemHover === id ? 'hover' : ''}`} 
      onClick={toggleCollapse}
    >
      <Link 
        to={navLink} 
        className="d-flex justify-content-start"
        onMouseEnter={() => setItemHover(id)}
        onMouseLeave={() => setItemHover('')}
        onClick={e => e.preventDefault()}
      >
        <div className="menu-text">
          {icon}
          <span className="menu-item menu-title">
            {title}
          </span>
        </div>
        <ChevronRight className="menu-toggle-icon" size={13} />
      </Link>
      <ul className="menu-content">
        {children.length && children.map(renderChild)}
      </ul>
    </li>
  );
}

export default MenuCollapse;
