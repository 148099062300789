/**
 *
 * Actions Types
 *
 */
export const Types = {
    PETSHOP_LOADING: 'petshop/PETSHOP_LOADING',
    PETSHOP_SUCCESS: 'petshop/PETSHOP_SUCCESS',
    PETSHOP_ERROR: 'petshop/PETSHOP_ERROR',
    PETSHOP_CLEAN: 'petshop/PETSHOP_CLEAN',

    PETSHOP_GET_PAGE: 'petshop/PETSHOP_GET_PAGE',
    PETSHOP_GET: 'petshop/PETSHOP_GET',
    PETSHOP_CREATE: 'petshop/PETSHOP_CREATE',
    PETSHOP_UPDATE: 'petshop/PETSHOP_UPDATE',
    PETSHOP_UPDATE_VOUCHER: 'petshop/PETSHOP_UPDATE_VOUCHER',
    PETSHOP_DELETE: 'petshop/PETSHOP_DELETE',
    PETSHOP_REQUEST_GEOLOCATION: 'petshop/PETSHOP_REQUEST_GEOLOCATION',
    PETSHOP_SET_LOCATION: 'petshop/PETSHOP_SET_LOCATION',

    PETSHOP_SET_PAGINATE: 'petshop/PETSHOP_SET_PAGINATE',
    PETSHOP_SET: 'petshop/PETSHOP_SET',
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    loading: false,
    success: false,
    error: null,

    data: {
        franchisee_id: "",
        email: "",
        username: "",
        password: "",
        company_name: "",
        phone: "",
        address: {
            name: "",
            street: "",
            state: "",
            city: "",
            number: "",
            street_line_two: "",
            district: "",
            zip: "",
            latitude: "",
            longitude: "",
        },
        show: false,
        website: "",
        voucher: {},
        categories: [],
        created: ""
    },

    petshopLocation: {
        lat: 0,
        lng: 0
    },

    rows: [],
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action

    switch (type) {
        case Types.PETSHOP_CLEAN:
            
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
                data: {},
                rows: [],
                page: 1,
                pages: 1,
                total: 0,
                limit: 10,
            }

        case Types.PETSHOP_GET_PAGE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.PETSHOP_CREATE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.PETSHOP_GET:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.PETSHOP_UPDATE  :
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.PETSHOP_DELETE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.PETSHOP_UPDATE_VOUCHER:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.PETSHOP_SET_PAGINATE:
            return {
                ...state,
                rows: payload.rows,
                page: payload.page,
                pages: payload.pages,
                total: payload.total,
                limit: payload.limit,
            }

        case Types.PETSHOP_SET_LOCATION:
            return{
                ...state,
                petshopLocation: payload
            }

        case Types.PETSHOP_SET:
            
            return {
                ...state,
                data: {
                    company_name: payload.company_name,
                    email: payload.email,
                    franchisee_id: payload.franchisee_id,
                    
                    website: payload.website,
                    phone: payload.phone,
                    address: payload.address,
                    categories: payload.categories,
                    conveniences: payload.conveniences,
                    show: payload.show,

                    voucher: payload.voucher,
                    
                    username: payload.username,
                    password: payload.password,

                    created: payload.created
                }
            }


        case Types.PETSHOP_LOADING:
            return {
                ...state,
                loading: payload,
            }

        case Types.PETSHOP_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.PETSHOP_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: payload.error
            }

        default:
            return state
    }
}

/**
 *
 * Actions Creators
 *
 */
export const petshopClean    = () => ({ type: Types.PETSHOP_CLEAN });
export const petshopGetPage  = payload => ({ type: Types.PETSHOP_GET_PAGE, payload: payload });

export const petshopGet             = payload => ({ type: Types.PETSHOP_GET, payload: payload });
export const petshopCreate          = payload => ({ type: Types.PETSHOP_CREATE, payload: payload });
export const petshopUpdate          = payload => ({ type: Types.PETSHOP_UPDATE, payload: payload });
export const petshopUpdateVoucher   = payload => ({ type: Types.PETSHOP_UPDATE_VOUCHER, payload: payload });
export const petshopDelete          = payload => ({ type: Types.PETSHOP_DELETE, payload: payload });
export const requestGeoLocation     = payload => ({ type: Types.PETSHOP_REQUEST_GEOLOCATION, payload: payload });
