import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, group, ...rest }) => {
    const user = useSelector((store) => store.user);
    const isAuth = useSelector((store) => store.auth.isAuth);

    let userGroup = user.group;
    let permited = group.includes(userGroup);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuth) {
                    if (permited) {
                        return <Component {...props} />;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/401',
                                    state: { from: props.location },
                                }}
                            />
                        );
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
};

export default PrivateRoute;
