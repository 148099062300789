import React from 'react';
import { List } from 'react-feather';

export default {
    id: 'breed-menu',
    title: 'Raças',
    navLink: '/breeds',
    group: ['admin'],
    icon: <List size={20} />,
};
