import React from 'react';
import { render } from 'react-dom';

// Component
import App from './App';
import * as serviceWorker from './serviceWorker';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Styles
import './styles/app.scss';

render(
  (
    <Provider store={store}>
      <App/>
    </Provider>
  ),
  document.getElementById('root')
);

serviceWorker.unregister();
