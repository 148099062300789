import React from 'react';
import { FileText } from 'react-feather';

export default {
    id: 'policy-menu',
    title: 'Termos',
    navLink: '/contracts',
    group: ['admin'],
    icon: <FileText size={20} />,
};
