/**
 *
 * Actions Types
 *
 */
export const Types = {
    SET_TOPIC: 'topic/SET_TOPIC',
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    data: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case Types.SET_TOPIC:
            return {
                ...state,
                data: payload,
            };

        default:
            return state;
    }
}

/**
 *
 * Actions Creators
 *
 */
export const setTopic = (payload) => ({
    type: Types.SET_TOPIC,
    payload,
});
