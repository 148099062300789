import Loadable from 'react-loadable';

import LoadingComponent from 'common/loading';

export default [
    {
        path: '/enterprise',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/enterprise'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/create',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/enterprise/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/edit',
        exact: true,
        auth: true,
        group: ['store'],
        component: Loadable({
            loader: () => import('pages/enterprise/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/edit/:id',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/enterprise/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/voucher/:id',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/voucher/:id/exceptions',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/exceptions/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/voucher/:id/exceptions/create',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/exceptions/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/voucher/:id/exceptions/:exception',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/exceptions/form/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/voucher/:id/schedule',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/schedule/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/voucher/:id/schedule/day',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'store'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/schedule/day/index'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/reviews/',
        exact: true,
        auth: true,
        group: ['store'],
        component: Loadable({
            loader: () => import('pages/enterprise/reviews'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/enterprise/reviews/:id',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee'],
        component: Loadable({
            loader: () => import('pages/enterprise/reviews'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/voucher',
        exact: true,
        auth: true,
        group: ['store'],
        component: Loadable({
            loader: () => import('pages/enterprise/voucher/index'),
            loading: LoadingComponent,
        }),
    },
];
