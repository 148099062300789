import React from 'react';
import { BarChart2 } from 'react-feather';

export default {
    id: 'analytics-menu',
    title: 'Analytics',
    navLink: '/analytics',
    group: ['admin', 'franchisee'],
    icon: <BarChart2 size={20} />,
};
