import React from "react"

const LoadingComponent = ({ loading }) => {

    if( !loading ) {
        return(<></>);
    }

    return (
        <div className="loading">
            carregando
        </div>
    );
}

export default LoadingComponent
