import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired,
};

function PublicLayout({ children }) {
    return (
        <div className="full-layout wrapper bg-pet blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="flexbox-container">
                            <div className="main w-100">{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PublicLayout.propTypes = propTypes;

export default PublicLayout;
