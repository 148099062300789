import React from 'react';
import { Video } from 'react-feather';

const videoPages = [
    {
        id: 'video-menu-admin',
        title: 'Cursos',
        navLink: '',
        group: ['admin'],
        type: 'collapse',
        icon: <Video size={20} />,
        children: [
            {
                id: 'video-categories-list',
                title: 'Categorias',
                navLink: '/videos/categories',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'video-list',
                title: 'Vídeos',
                navLink: '/videos',
                type: 'item',
                group: ['admin'],
                icon: null,
            },
            {
                id: 'video-preview',
                title: 'Preview',
                navLink: '/videos/preview',
                type: 'item',
                group: ['admin', 'store', 'franchisee'],
                icon: null,
            },
        ],
    },
    {
        id: 'video-menu',
        title: 'Cursos',
        navLink: '/videos/preview',
        group: ['store', 'franchisee'],
        type: 'item',
        icon: <Video size={20} />,
        children: []
    },
];

export default videoPages;
