import React from 'react';
import { Send } from 'react-feather';

export default {
    id: 'notifications-menu',
    title: 'Notificações',
    navLink: '/push-notifications',
    group: ['admin'],
    icon: <Send size={20} />,
};
