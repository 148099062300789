import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import routes from './routes';
import PrivateRoute from './private';
import PublicRoute from './public';

import Layout from 'layout';
import config from 'config';
import Page404 from 'pages/errors/404';

const history = createBrowserHistory();

const Routes = () => (
    <Router history={history} basename={config.app.BASENAME}>
        <Layout>
            <Switch>
                {routes.map((route, i) => {
                    if (route.auth) {
                        return <PrivateRoute key={i} {...route} />;
                    }

                    return <PublicRoute key={i} {...route} />;
                })}

                <Route component={Page404} />
            </Switch>
        </Layout>
    </Router>
);

export default Routes;
