import Loadable from "react-loadable";

import LoadingComponent from "common/loading";

export default [
    {
        path: "/franchisees",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("pages/franchisees"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/create",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("pages/franchisees/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisees/edit/:id",
        exact: true,
        auth: true,
        group: ["admin"],
        component: Loadable({
            loader: () => import("pages/franchisees/form"),
            loading: LoadingComponent
        })
    }
];
