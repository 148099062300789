import React from 'react';
import { Home, File, Star, Layers } from 'react-feather';

import regions from './regions';
import franchisees from './franchisees';
import petshops from './petshops';
import store from './store';
import coupon from './coupon';
import faq from './faq';
import leads from './leads';
import contracts from './contracts';
import breeds from './breeds';
import users from './users';
import orders from './orders';
import financial from './financial';
import logs from './logs';
import config from './config';
import analytics from './analytics';
import notifications from './notifications';

import videoPages from './videos';
import citiesPages from './cities';
import enterprisePages from './enterprise';

const navPages = [
    {
        id: 'dashboard-menu',
        title: 'Dashboard',
        navLink: '/',
        group: ['admin', 'store', 'franchisee'],
        icon: <Home size={20} />,
    },
    financial,
    orders,
    ...citiesPages,
    ...regions,
    ...franchisees,
    ...petshops,
    ...enterprisePages,
    ...store,
    ...coupon,
    ...leads,
    notifications,
    users,
    analytics,
    ...faq,
    contracts,
    breeds,
    logs,
    ...config,
    {
        id: 'Reviews',
        title: 'Avaliações',
        navLink: '/petshops/reviews',
        group: ['store'],
        icon: <Star size={20} />,
    },
    {
        id: 'vouchers',
        title: 'Editar Vouchers',
        navLink: '/voucher',
        group: ['store'],
        icon: <File size={20} />,
    },
    {
        id: 'editpetshop',
        title: 'Editar dados',
        navLink: '/petshops/edit',
        group: ['store'],
        icon: <Layers size={20} />,
    },
    ...videoPages
];

export default navPages;
