import Http, { setHeaderToken } from 'lib/http';

export const requestSignIn = async (credentials) => {
    return Http.post('/auth', credentials)
        .then((response) => {
            if (response && response.data.success) {
                const { token } = response.data.data;
                return { token };
            }

            throw response;
        })
        .catch((err) => {
            if (typeof err == 'undefined') {
                return { error: 'Request failed! Please try again!' };
            }

            return { error: 'Email or password are wrong' };
        });
};

export const requestForgotPassword = async (data) => {
    return Http.post('/auth/forgot_password', data)
        .then((response) => {
            if (response && response.data.success) {
                return 'Se o seu username estiver correto, você receberá em breve um email de alteração de senha';
            }

            throw response;
        })
        .catch((err) => {
            if (typeof err == 'undefined') {
                return { error: 'Request failed! Please try again!' };
            }

            return {
                error:
                    'Se o seu username estiver correto, você receberá em breve um email de alteração de senha',
            };
        });
};

export const requestPasswordReset = async (data) => {
    return Http.post('/auth/reset_password', data).then((response) => {
        if (response && response.data.success) {
            return response;
        }

        throw response;
    });
};

export const saveToken = (token) => {
    setHeaderToken(token);
    localStorage.setItem('token', token);
};

export const removeToken = () => {
    setHeaderToken('');
    localStorage.removeItem('token');
};
