import React from 'react';
import { Tag } from 'react-feather';

const couponPages = [
  {
    id: 'coupon-menu',
    title: 'Cupons',
    navLink: '',
    type: 'collapse',
    group: ['admin', 'franchisee'],
    icon: <Tag size={20} />,
    children: [
      {
        id: 'coupon-list',
        title: 'Todos',
        navLink: '/coupon',
        type: 'item',
        group: ['admin', 'franchisee'],
        icon: null,
      },
      {
        id: 'coupon-new',
        title: 'Cadastrar',
        navLink: '/coupon/create',
        type: 'item',
        group: ['admin', 'franchisee'],
        icon: null,
      },
      {
        id: 'coupon-notifications',
        title: 'Notificação de Cupom',
        navLink: '/coupon-notification',
        type: 'item',
        group: ['admin'],
        icon: null,
      },
    ],
  },
];

export default couponPages;
