import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { fetchUser } from 'services/user';

import PrivateLayout from './private';
import PublicLayout from './public';

import ComponentSpinner from 'components/@vuexy/spinner/Fallback-spinner';

class Layout extends Component {
    static displayName = 'Layout';

    static propTypes = {
        isAuth: PropTypes.bool,
        user: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    render() {
        const { isAuth, children, user, ...props } = this.props;

        if (isAuth) {
            if (user._id === '') {
                return <ComponentSpinner />;
            }

            return <PrivateLayout {...props}>{children}</PrivateLayout>;
        }

        return <PublicLayout {...props}>{children}</PublicLayout>;
    }
}

const mapStateToProps = (state) => {
    return {
        isAuth: state.auth.isAuth,
        user: state.user,
        blocked: false,
    };
};

export default withRouter(connect(mapStateToProps)(Layout));
