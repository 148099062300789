import Loadable from "react-loadable";

import LoadingComponent from "common/loading";

export default [
  {
    path: "/coupon",
    exact: true,
    auth: true,
    group: ["admin", "franchisee"],
    component: Loadable({
      loader: () => import("pages/coupon"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/coupon/create",
    exact: true,
    auth: true,
    group: ["admin", "franchisee"],
    component: Loadable({
      loader: () => import("pages/coupon/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/coupon/edit/:id",
    exact: true,
    auth: true,
    group: ["admin", "franchisee"],
    component: Loadable({
      loader: () => import("pages/coupon/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/coupon-notification",
    exact: true,
    auth: true,
    group: ["admin", "franchisee"],
    component: Loadable({
      loader: () => import("pages/coupon-notification"),
      loading: LoadingComponent,
    }),
  },
];
