import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import navPages from 'assets/data/navPages';

import MenuTitle from './MenuTitle';
import MenuItem from './MenuItem';
import MenuCollapse from './MenuCollapse';

const Menu = () => {

	const user = useSelector( store => store.user );

	const renderMenuItem = ( menuItem ) => {
		const { id, title, type, group } = menuItem;

		if (group.includes( user.group )) {
			if (type === 'groupHeader') {
				return <MenuTitle title={title} key={id} />;
			}

			if (type === 'collapse') {
				return <MenuCollapse menuItem={menuItem} key={id} />;
			}

			return <MenuItem menuItem={menuItem} key={id} />;
		}

		return <Fragment key={id} />;
	}

	return(
		<ul className="navigation navigation-main">
			{ navPages.map( renderMenuItem ) }
		</ul>
	)
}

export default Menu;
