/**
 *
 * Actions Types
 *
 */
export const Types = {
    FRANCHISEE_LOADING: 'franchisee/FRANCHISEE_LOADING',
    FRANCHISEE_SUCCESS: 'franchisee/FRANCHISEE_SUCCESS',
    FRANCHISEE_ERROR: 'franchisee/FRANCHISEE_ERROR',
    FRANCHISEE_CLEAN: 'franchisee/FRANCHISEE_CLEAN',

    FRANCHISEE_GET_ALL: 'franchisee/FRANCHISEE_GET_ALL',
    FRANCHISEE_GET_PAGE: 'franchisee/FRANCHISEE_GET_PAGE',
    FRANCHISEE_GET: 'franchisee/FRANCHISEE_GET',
    FRANCHISEE_CREATE: 'franchisee/FRANCHISEE_CREATE',
    FRANCHISEE_UPDATE: 'franchisee/FRANCHISEE_UPDATE',
    FRANCHISEE_DELETE: 'franchisee/FRANCHISEE_DELETE',

    FRANCHISEE_SET_OPTION_LIST: 'franchisee/FRANCHISEE_SET_OPTION_LIST',
    FRANCHISEE_SET_PAGINATE: 'franchisee/FRANCHISEE_SET_PAGINATE',
    FRANCHISEE_SET: 'franchisee/FRANCHISEE_SET_L',
};

/**
 *
 * Reducers
 *
 */

const initialState = {
    loading: false,
    success: false,
    error: null,

    data: {
        region: {
            id: "",
            name: "",
            slug: ""
        },
        regionOption: [],
        ppo: 0,
        _id: "",
        email: "",
        first_name: "",
        last_name: "",
        username: ""
    },

    rowsOption: [],

    rows: [],
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
}

export default function reducer(state = initialState, action) {

    const { type, payload } = action

    switch (type) {
        case Types.FRANCHISEE_CLEAN:
            return {
                ...state,
                loading: false,
                success: false,
                error: null,
                data: {}
            }

        case Types.FRANCHISEE_GET_PAGE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.FRANCHISEE_CREATE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.FRANCHISEE_GET_ALL:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.FRANCHISEE_GET:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.FRANCHISEE_UPDATE  :
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.FRANCHISEE_DELETE:
            return {
                ...state,
                loading: true,
                success: false,
                error: null
            }

        case Types.FRANCHISEE_SET_PAGINATE:
            return {
                ...state,
                rows: payload.rows,
                page: payload.page,
                pages: payload.pages,
                total: payload.total,
                limit: payload.limit,
            }

        case Types.FRANCHISEE_SET_OPTION_LIST:
            return {
                ...state,
                rowsOption: payload.rowsOption,
            }

        case Types.FRANCHISEE_SET:
            
            return {
                ...state,
                data: {
                    region: {
                        id: payload.region.id,
                        name: payload.region.name,
                        slug: payload.region.slug
                    },
                    regionOption: payload.regionOption,
                    ppo: payload.ppo,
                    _id: payload._id,
                    email: payload.email,
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    username: payload.username
                }
            }


        case Types.FRANCHISEE_LOADING:
            return {
                ...state,
                loading: false,
            }

        case Types.FRANCHISEE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null
            }

        case Types.FRANCHISEE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: payload
            }

        default:
            return state
    }
}

/**
 *
 * Actions Creators
 *
 */
export const franchiseeClean    = () => ({ type: Types.FRANCHISEE_CLEAN });
export const franchiseeGetPage  = payload => ({ type: Types.FRANCHISEE_GET_PAGE, payload: payload });
export const franchiseeGetAll   = payload => ({ type: Types.FRANCHISEE_GET_ALL, payload: payload });

export const franchiseeGet      = payload => ({ type: Types.FRANCHISEE_GET, payload: payload });
export const franchiseeCreate   = payload => ({ type: Types.FRANCHISEE_CREATE, payload: payload });
export const franchiseeUpdate   = payload => ({ type: Types.FRANCHISEE_UPDATE, payload: payload });
export const franchiseeDelete   = payload => ({ type: Types.FRANCHISEE_DELETE, payload: payload });
